<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg">
   <g transform="translate(-370 -688)">
    <rect :fill="background" height="60" transform="translate(370 688)" width="60"/>
    <g transform="translate(381.477 713.418)">
     <path d="M158.463,193.875a14.113,14.113,0,0,1-7.96,2.433,14.4,14.4,0,0,1-9.724-3.711c-.2-.182-.021-.431.221-.289a19.571,19.571,0,0,0,9.729,2.58,19.346,19.346,0,0,0,7.421-1.517c.364-.155.669.239.313.5" fill="#f90" fill-rule="evenodd" transform="translate(-135.48 -185.144)"/>
     <path d="M574.583,182.786c-.274-.352-1.819-.166-2.512-.084-.211.026-.243-.158-.053-.29a3.864,3.864,0,0,1,3.485-.326,3.821,3.821,0,0,1-1.218,3.282c-.177.148-.347.069-.268-.127.26-.648.842-2.1.566-2.454" fill="#f90" fill-rule="evenodd" transform="translate(-550.705 -175.079)"/>
     <path d="M572.451,5.495V4.653a.208.208,0,0,1,.213-.213h3.769a.21.21,0,0,1,.218.213v.721a1.186,1.186,0,0,1-.284.529l-1.953,2.788a4.161,4.161,0,0,1,2.15.461.383.383,0,0,1,.2.327v.9c0,.123-.135.266-.277.192a4.341,4.341,0,0,0-3.982.006c-.131.071-.268-.071-.268-.194V9.53a1.018,1.018,0,0,1,.139-.579l2.262-3.245h-1.969a.208.208,0,0,1-.218-.211" fill-rule="evenodd" transform="translate(-551.038 -4.276)"/>
     <path d="M171.812,7.939h-1.147a.217.217,0,0,1-.2-.2V1.859a.215.215,0,0,1,.221-.211h1.069a.216.216,0,0,1,.208.2v.769h.021a1.576,1.576,0,0,1,3,0,1.693,1.693,0,0,1,2.911-.447,3.131,3.131,0,0,1,.289,1.845l0,3.714a.217.217,0,0,1-.221.213H176.81a.218.218,0,0,1-.206-.213V4.607a7.364,7.364,0,0,0-.032-1.1A.6.6,0,0,0,175.9,3a.762.762,0,0,0-.685.482,3.562,3.562,0,0,0-.106,1.127V7.726a.217.217,0,0,1-.221.213h-1.145a.217.217,0,0,1-.206-.213l0-3.119c0-.656.108-1.622-.706-1.622s-.792.942-.792,1.622V7.726a.217.217,0,0,1-.221.213" fill-rule="evenodd" transform="translate(-164.146 -1.467)"/>
     <path d="M711.891,1.524c1.7,0,2.622,1.461,2.622,3.319,0,1.795-1.018,3.219-2.622,3.219-1.671,0-2.58-1.461-2.58-3.282s.921-3.256,2.58-3.256m.01,1.2c-.845,0-.9,1.151-.9,1.869s-.011,2.254.889,2.254.93-1.238.93-1.993a5.443,5.443,0,0,0-.171-1.561.717.717,0,0,0-.75-.569" fill-rule="evenodd" transform="translate(-683.034 -1.467)"/>
     <path d="M874.2,7.939h-1.142a.218.218,0,0,1-.206-.213l0-5.886a.217.217,0,0,1,.221-.192h1.063a.219.219,0,0,1,.2.164v.9h.021a1.56,1.56,0,0,1,1.563-1.188,1.5,1.5,0,0,1,1.338.693,3.739,3.739,0,0,1,.3,1.832v3.7a.22.22,0,0,1-.221.185h-1.15a.217.217,0,0,1-.2-.185v-3.2c0-.643.074-1.585-.718-1.585a.749.749,0,0,0-.663.471,2.6,2.6,0,0,0-.182,1.114V7.726a.22.22,0,0,1-.224.213" fill-rule="evenodd" transform="translate(-840.515 -1.467)"/>
     <path d="M409.116,3.661a2.243,2.243,0,0,1-.214,1.216.932.932,0,0,1-.793.521c-.44,0-.7-.335-.7-.83,0-.977.876-1.155,1.706-1.155v.248m1.156,2.795a.239.239,0,0,1-.271.027,2.794,2.794,0,0,1-.658-.764,2.27,2.27,0,0,1-1.89.834,1.626,1.626,0,0,1-1.716-1.787,1.946,1.946,0,0,1,1.222-1.874,7.944,7.944,0,0,1,2.156-.4V2.345a1.462,1.462,0,0,0-.14-.83.763.763,0,0,0-.643-.3.872.872,0,0,0-.921.689.24.24,0,0,1-.2.21L406.1,2a.2.2,0,0,1-.171-.24A2.327,2.327,0,0,1,408.493,0a2.6,2.6,0,0,1,1.727.571c.558.521.5,1.216.5,1.972V4.33a1.568,1.568,0,0,0,.432,1.063.217.217,0,0,1,0,.3c-.234.2-.65.558-.879.761l0,0" fill-rule="evenodd" transform="translate(-390.706)"/>
     <path d="M3.378,3.661a2.243,2.243,0,0,1-.214,1.216.93.93,0,0,1-.793.521c-.44,0-.7-.335-.7-.83,0-.977.876-1.155,1.7-1.155v.248M4.535,6.455a.239.239,0,0,1-.271.027,2.823,2.823,0,0,1-.658-.764,2.269,2.269,0,0,1-1.89.834A1.627,1.627,0,0,1,0,4.765,1.948,1.948,0,0,1,1.222,2.892a7.945,7.945,0,0,1,2.156-.4V2.345a1.475,1.475,0,0,0-.139-.83.767.767,0,0,0-.643-.3.874.874,0,0,0-.922.689.24.24,0,0,1-.2.21L.363,2a.2.2,0,0,1-.171-.24A2.328,2.328,0,0,1,2.756,0,2.6,2.6,0,0,1,4.483.571c.558.521.5,1.216.5,1.972V4.33A1.568,1.568,0,0,0,5.42,5.393a.215.215,0,0,1,0,.3c-.234.2-.65.558-.879.761l0,0" fill-rule="evenodd"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>